<template>
  <tr class='border-b'>
    <td class='px-2 py-2 print:py-1 text-left border-r border-l'>{{ result.fund_name }}</td>
    <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(result.nav_amount) }}</td>
    <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ navRatio }} %</td>
    <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(result.estimated_quantity) }}</td>
    <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(result.estimated_price) }}</td>
    <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(result.estimated_amount) }}</td>
    <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ estimatedRatio }} %</td>
    <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(result.total_allocated_quantity) || 0 }}</td>
    <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(allocatedPrice )}}</td>
    <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(allocatedAmount) || 0}}</td>
    <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ allocatedRatio || 0.0 }} %</td>
    <td v-if='isViewRatio' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(result.new_allocated_quantity) || 0}}</td>
    <td v-if='isViewRatio' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(result.old_allocated_quantity) || 0}}</td>
    <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ verifyEstimatedRatio }} %</td>
    <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ verifyAllocatedRatio }} %</td>
    <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r' :style='verificationColor'>{{ verification }}</td>
  </tr>
</template>

<script>

export default {
  name: 'IpoDraftRow',
  props: [
    'result',
    'totalEstimatedQuantity',
    'totalAllocatedQuantity',
    'isViewRatio',
    'isNewNOldStocks',
  ],
  computed: {
    navRatio () {
      return (Math.round(this.result.nav_ratio * 10000) / 100).toFixed(1)
    },
    estimatedRatio () {
      return (Math.round(this.result.estimated_quantity / this.totalEstimatedQuantity * 10000) / 100).toFixed(1)
    },
    allocatedRatio () {
      return (Math.round(this.result.total_allocated_quantity / this.totalAllocatedQuantity * 10000) / 100).toFixed(1)
    },
    allocatedPrice () {
      return this.result.allocated_price * (100 + this.result.ipo_summary.subs_fee_rate) / 100
    },
    allocatedAmount () {
      return this.result.total_allocated_quantity * this.allocatedPrice
    },
    verifyEstimatedRatio () {
      return (Math.round(this.result.nav_ratio * 10000) / 100 - Math.round(this.result.estimated_quantity / this.totalEstimatedQuantity * 10000) / 100).toFixed(1)
    },
    verifyAllocatedRatio () {
      return (Math.round(this.result.nav_ratio * 10000) / 100 - Math.round(this.result.total_allocated_quantity / this.totalAllocatedQuantity * 10000) / 100).toFixed(1)
    },
    verification () {
      return (this.result.new_allocated_quantity + this.result.old_allocated_quantity) === this.result.total_allocated_quantity ? 'TRUE' : '오류'
    },
    verificationColor () {
      return this.verification === 'TRUE' ? 'background-color: yellow;' : 'background-color: red;'
    }
  },
  methods: {
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
  }
}
</script>

<style lang='scss' scoped>
  @media print {
    @page {
      margin: 1cm;
      size: landscape;
    }
  }
</style>
