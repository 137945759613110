<template>
  <table class='bg-white mx-auto' :style='landscapeOrPortraitWidth'>
    <thead>
      <tr>
        <td>
          <header class='my-8 print:my-4 w-full font-semibold text-center text-2xl'>
            수요예측 신청 ・ 배분 보고
          </header>
        </td>
      </tr>
    </thead>
    <tbody>
      <table class='text-sm text-gray-800 text-center'>
        <tr class=''>
          <td class='w-32 bg-gray-200 px-2 py-1 border font-semibold'>계좌</td>
          <td class='border px-2 py-1' style='min-width: 320px;'>{{ accountTypeName }}</td>
        </tr>
        <tr class=''>
          <td class='w-32 bg-gray-200 px-2 py-1 border font-semibold'>종목명</td>
          <td class='border px-2 py-1' style='min-width: 320px;'>{{ selectedIpo.name }}</td>
        </tr>
        <tr class=''>
          <td class='w-32 bg-gray-200 px-2 py-1 border font-semibold'>증권사</td>
          <td class='border px-2 py-1' style='min-width: 320px;'>{{ brokerName }}</td>
        </tr>
        <tr class=''>
          <td class='w-32 bg-gray-200 px-2 py-1 border font-semibold'>수요예측일</td>
          <td class='border px-2 py-1' style='min-width: 320px;'>{{ predictedDate }}</td>
        </tr>
        <tr class=''>
          <td class='w-32 bg-gray-200 px-2 py-1 border font-semibold'>청약일</td>
          <td class='border px-2 py-1' style='min-width: 320px;'>{{ subscriptionDate }}</td>
        </tr>
        <tr class=''>
          <td class='w-32 bg-gray-200 px-2 py-1 border font-semibold'>납일일</td>
          <td class='border px-2 py-1' style='min-width: 320px;'>{{ selectedIpo.payment_date }}</td>
        </tr>
      </table>
      <table class='whitespace-no-wrap mt-8 print:mt-4'>
        <thead>
          <tr class='border-b text-xs bg-gray-200 text-center'>
            <th class='w-64 px-2 py-2 print:py-1 border-t border-l border-r' colspan='1' rowspan='2'>집합투자기구명</th>
            <th v-if='!isNewNOldStocks' class='w-24 px-2 py-2 print:py-1 border-t border-r' colspan='1' rowspan='2'>순자산총액</th>
            <th v-if='!isNewNOldStocks' class='w-16 px-2 py-2 print:py-1 border-t border-r' colspan='1' rowspan='2'>순자산총액<br>비율</th>
            <th v-if='!isNewNOldStocks' class='w-1/4 px-2 py-2 print:py-1 border-t border-r' colspan='4'>수요예측</th>
            <th v-if='!isNewNOldStocks' class='w-1/4 px-2 py-2 print:py-1 border-t border-r' colspan='4'>청약(배분)</th>
            <th v-if='isViewRatio' class='w-1/4 px-2 py-2 print:py-1 border-t border-r' colspan='2'>벤처수량분리</th>
            <th v-if='!isNewNOldStocks' class='w-1/4 px-2 py-2 print:py-1 border-t border-r' colspan='3' rowspan='2'>검증</th>
          </tr>
          <tr class='border-b text-xs bg-gray-200 text-center'>
            <th v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 border-r'>수량</th>
            <th v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 border-r'>단가</th>
            <th v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 border-r'>금액</th>
            <th v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 border-r'>신청비율</th>
            <th v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 border-r'>수량</th>
            <th v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 border-r'>단가</th>
            <th v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 border-r'>금액</th>
            <th v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 border-r'>배분비율</th>
            <th v-if='isViewRatio' class='px-2 py-2 print:py-1 border-r'>신주</th>
            <th v-if='isViewRatio' class='px-2 py-2 print:py-1 border-r'>구주</th>
          </tr>
        </thead>
        <tbody style='font-size:11px;'>
          <ipo-draft-row v-for='result in results'
                         :key='result.id'
                         :result='result'
                         :is-view-ratio='isViewRatio'
                         :is-new-n-old-stocks='isNewNOldStocks'
                         :total-estimated-quantity='totalEstimatedQuantity'
                         :total-allocated-quantity='totalAllocatedQuantity' />
        </tbody>
        <tfoot>
          <tr class='border-b text-xs bg-gray-200 font-semibold'>
            <td class='px-2 py-2 print:py-1 text-center border-r border-l'>합계</td>
            <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(totalNavAmount) }}</td>
            <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(totalNavRatio) }} %</td>
            <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(totalEstimatedQuantity) }}</td>
            <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(totalEstimatedPrice) }}</td>
            <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(totalEstimatedAmount) }}</td>
            <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(totalEstimatedRatio) }} %</td>
            <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(totalAllocatedQuantity) || 0 }}</td>
            <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(totalAllocatedPrice) || 0 }}</td>
            <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(totalAllocatedAmount) || 0 }}</td>
            <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(totalAllocatedRatio) || 0 }} %</td>
            <td v-if='isViewRatio' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(totalNewAllocateQuantity) || 0 }}</td>
            <td v-if='isViewRatio' class='px-2 py-2 print:py-1 text-right border-r'>{{ numberStyle(totalOldAllocateQuantity) || 0 }}</td>
            <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ verifyTotalEstimatedRatio}} %</td>
            <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'>{{ verifyTotalAllocatedRatio}} %</td>
            <td v-if='!isNewNOldStocks' class='px-2 py-2 print:py-1 text-right border-r'></td>
          </tr>
        </tfoot>
      </table>
    </tbody>
  </table>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import IpoDraftRow from '@/views/ipo/IpoDraftRow.vue'

export default {
  name: 'IpoDraft',
  components: {
    IpoDraftRow,
  },
  data () {
    return {
      ipo_id: this.$route.query.ipo_id,
      account_type: this.$route.query.account_type,
      is_view_ratio: this.$route.query.is_view_ratio,
      is_new_n_old_stocks: this.$route.query.is_new_n_old_stocks,
      pageLayout: 'landscape',
    }
  },
  computed: {
    ...mapFields('ipos', [
      'selectedIpoId',
    ]),
    ...mapState('ipos', [
      'selectedIpo',
      'ipoSummaries',
      'ipoResults',
    ]),
    ...mapState('brokers', [
      'brokers',
    ]),
    isViewRatio () {
      return this.is_new_n_old_stocks == 'true' ? true : this.is_view_ratio == 'true' ? true : false
    },
    isNewNOldStocks () {
      return this.is_new_n_old_stocks == 'true' ? true : false
    },
    landscapeOrPortraitWidth () {
      return (this.pageLayout === 'portrait') ? 'width: 210mm;' : 'width: 297mm;'
    },
    results () {
      return this.ipoResults.filter(result => result.account_type === this.account_type)
    },
    ipoSummary () {
      return this.ipoSummaries.find(summary => summary.account_type === this.account_type)
    },
    accountTypeName () {
      return this.account_type === '집합' ? '벤처外' : this.account_type
    },
    brokerId () {
      return this.ipoSummaries[0] ? this.ipoSummaries[0].broker_id : 0
    },
    brokerName () {
      let found = this.brokers.find(broker => broker.id === this.brokerId)
      return found ? found.name : ''
    },
    predictedDate () {
      return this.ipoSummary ? this.ipoSummary.predicted_date : ''
    },
    subscriptionDate () {
      return this.ipoSummary ? this.ipoSummary.subscription_date : ''
    },
    totalNavAmount () {
      return this.results ? this.results.reduce((sum, result) => {
        return sum + result.nav_amount
      }, 0) : 0
    },
    totalNavRatio () {
      return this.results ? Math.round(this.results.reduce((sum, result) => {
        return sum + (result.nav_ratio * 100)
      }, 0)) : 0
    },
    totalEstimatedQuantity () {
      return this.results ? this.results.reduce((sum, result) => {
        return sum + result.estimated_quantity
      }, 0) : 0
    },
    totalEstimatedPrice () {
      return this.results ? this.results.reduce((sum, result) => {
        return result.estimated_price
      }, 0) : 0
    },
    totalEstimatedAmount () {
      return this.results ? this.results.reduce((sum, result) => {
        return sum + result.estimated_amount
      }, 0) : 0
    },
    totalAllocatedQuantity () {
      return this.results ? this.results.reduce((sum, result) => {
        return sum + result.total_allocated_quantity
      }, 0) : 0
    },
    totalNewAllocateQuantity () {
      return this.results ? this.results.reduce((sum, result) => {
        return sum + result.new_allocated_quantity
      }, 0) : 0
    },
    totalOldAllocateQuantity () {
      return this.results ? this.results.reduce((sum, result) => {
        return sum + result.old_allocated_quantity
      }, 0) : 0
    },
    totalAllocatedPrice () {
      return this.results ? this.results.reduce((sum, result) => {
        let summary = this.ipoSummaries.find(summary => summary.account_type === result.account_type)
        let subs_fee_rate = summary ? summary.subs_fee_rate : 0

        return result.allocated_price * (100 + subs_fee_rate) / 100
      }, 0) : 0
    },
    totalAllocatedAmount () {
      return this.results ? this.results.reduce((sum, result) => {
        return sum + result.total_allocated_quantity * this.totalAllocatedPrice
      }, 0) : 0
    },
    totalEstimatedRatio () {
      return this.results ? Math.round(this.results.reduce((sum, result) => {
        return sum + (result.estimated_quantity / this.totalEstimatedQuantity)
      }, 0)) * 100 : 0
    },
    totalAllocatedRatio () {
      return this.results ? Math.round(this.results.reduce((sum, result) => {
        return sum + (result.total_allocated_quantity / this.totalAllocatedQuantity)
      }, 0)) * 100 : 0
    },
    verifyTotalEstimatedRatio () {
      return this.totalNavRatio - this.totalEstimatedRatio
    },
    verifyTotalAllocatedRatio () {
      return this.totalNavRatio - this.totalAllocatedRatio
    },
  },
  methods: {
    ...mapActions('ipos', [
      'getIpos',
      'getIpoSummaries',
      'getHistoricalIpo',
      'updateSelectedIpo',
    ]),
    ...mapActions('brokers', [
      'getBrokers',
    ]),
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
  },
  mounted () {
    this.getBrokers()
    this.getIpos().then(() => {
      this.selectedIpoId = parseInt(this.$route.query.ipo_id)
      this.getIpoSummaries()
      this.getHistoricalIpo()
      this.updateSelectedIpo()
    })
  }
}
</script>

<style lang='scss' scoped>
  @media print {
    @page {
      margin: 1cm;
      size: landscape;
    }
  }
</style>
